import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { Navs } from "./utils";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ProfileLogoutTab from "./ProfileLogoutTab";

const MobNav = () => {
  const [nav, setNav] = useState(useLocation().pathname);
  // const [open, setOpen] = useState(false)
  const user_type = localStorage.getItem("user_type");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setNav(e.target.value);
    // setOpen(false);
    navigate(e.target.value);
  };

  const renderItms = (item, index) => {
    const defaultView = () => {
      return (
        <MenuItem
          key={index}
          value={item.value}
          className="nav-item"
          sx={{
            borderBottom:
              index !== Navs.flat().length - 1
                ? "1px solid rgba(256,256,256,0.15)"
                : "none",
          }}
        >
          <Box sx={{ display: "flex", fontSize: "1rem" }}>
            <img src={item.img} alt="" />
            <Typography px={2} py={0.5}>
              {item.name}
            </Typography>
          </Box>
        </MenuItem>
      );
    };
    switch (item.name) {
      case "Reports":
        return user_type === "2" ? defaultView() : null;
      case "Payout":
        return user_type === "2" ? defaultView() : null;
      case "Logout":
        return <ProfileLogoutTab key={index} />;
      case "Company Profile":
        if (user_type === "2") {
          return defaultView(item);
        }
        return null;
      case "My Profile":
        if (user_type === "1") {
          return defaultView(item);
        }
        return null;
      case "KYC Documentations":
        return user_type === "2" ? defaultView() : null;
      default:
        return defaultView(item);
    }
  };

  return (
    <Box display={"flex"} flexDirection="column" alignItems={"center"} p={1}>
      <FormControl variant="filled">
        <InputLabel id="demo-simple-select-label" sx={{ color: "white" }}>
          Dashboard Navigation
        </InputLabel>
        <Select
          className="navBarDash_mob list"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Dashboard Navigation"
          // open={open}
          // onClose={() => setOpen(false)}
          // onOpen={() => setOpen(true)}
          value={nav}
          onChange={handleChange}
          inputProps={{
            sx: {
              color: "white",
              background: "rgba(255, 255, 255, 0.1)",
            },
            MenuProps: {
              MenuListProps: {
                sx: {
                  padding: "0.5rem 0.5rem",
                  color: "white",
                  background:
                    "linear-gradient(162.44deg, #207028 0%, #0B360F 100%)",
                },
              },
            },
          }}
        >
          {Navs.flat().map((item, index) => renderItms(item, index))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default MobNav;
