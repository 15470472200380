import DashboardIcon from "../../../assets/images/dashboard.svg";
import TransactionHistoryIcon from "../../../assets/images/transaction-history.svg";
import PaymentRequestHistoryIcon from "../../../assets/images/payment-request.svg";
import ReportIcon from "../../../assets/images/reports.svg";
import PayoutIcon from "../../../assets/images/payout.svg";
// import SupportIcon from "../../../assets/images/support.svg";
import MyProfileIcon from "../../../assets/images/myprofile.svg";
import CompanyProfileIcon from "../../../assets/images/company-profile.svg";
import KycDocumentIcon from "../../../assets/images/kyc-documentaion.svg";
import SettingsIcon from "../../../assets/images/setting.svg";
import ManageSettingsIcon from "../../../assets/images/manage-settings.svg";

const nav1Item = [
  { name: "Dashboard", value: "/dashboard", img: DashboardIcon },
  {
    name: "Transaction History",
    value: "/dashboard/transaction-history",
    img: TransactionHistoryIcon,
  },
  {
    name: "Payment Request History",
    value: "/dashboard/payment-request-history",
    img: PaymentRequestHistoryIcon,
  },
  { name: "Reports", value: "/dashboard/reports", img: ReportIcon },
  { name: "Payout", value: "/dashboard/payout", img: PayoutIcon },
  // { name: "Support", value: "/dashboard/support", img: SupportIcon },
];
const nav2Item = [
  { name: "My Profile", value: "/dashboard/my-profile", img: MyProfileIcon },
  {
    name: "Company Profile",
    value: "/dashboard/company-profile",
    img: CompanyProfileIcon,
  },
  {
    name: "KYC Documentations",
    value: "/dashboard/kyc-documentation",
    img: KycDocumentIcon,
  },
];
const nav3Item = [
  { name: "Logout", value: "null", img: "" },
  { name: "Settings", value: "/dashboard/settings", img: SettingsIcon },
  {
    name: "Manage Security",
    value: "/dashboard/manage-security",
    img: ManageSettingsIcon,
  },
];
export const Navs = [nav1Item, nav2Item, nav3Item];
