export const faqQues = [
  {
    ques: "What is Your Wallet Botswana App?",
    ans: "The Your Wallet Botswana App is a secure and user-friendly mobile application that allows you to manage all your financial transactions in one place. With this app, you can:",
    points: {
      type: "ul",
      list: [
        "Make Payments: Pay for goods and services online and in-store with just a few taps.",
        "Transfer Money: Send money to friends, family, or businesses quickly and securely.",
        "Pay Bills: Easily settle your utility, internet, and other recurring bills from within the app.",
        "Manage Your Wallet: Keep track of your account balance, transaction history, and more, all in real-time.",
      ],
    },
    afterPoints:
      "The app is designed to make your life easier by providing a fast, reliable, and convenient way to handle your everyday payments and transfers, all from the comfort of your smartphone.",
  },
  {
    ques: "How to Add a Payment Method in the Your Wallet Botswana App?",
    ans: "Adding a payment method in the Your Wallet Botswana App is quick and simple. Just follow these steps:",
    points: {
      type: "ol",
      list: [
        "Open the App: Launch the Your Wallet Botswana App on your smartphone.",
        "Go to Settings: Tap on the menu icon (usually found in the top corner) and select “Settings” or “Payment Methods.”",
        " Add New Payment Method: Click on “Add Payment Method” and choose the type of payment method you want to add (e.g., credit/debit card, bank account, or mobile wallet).",
        "Enter Details: Fill in the required information, such as card number, expiry date, or bank account details.",
        "Verify Your Information: Some payment methods may require you to verify your information for security purposes. Follow the on-screen instructions to complete verification.",
        "Save Your Payment Method: Once verified, tap “Save” to add the payment method to your account.",
      ],
    },
    afterPoints:
      "Your payment method is now ready to use for making payments, transferring money, and paying bills directly through the app.",
  },
  {
    ques: "Can I Transfer Money from the Your Wallet Botswana App to My Bank Account?",
    ans: (
      <>
        Yes, you can easily transfer money from your{" "}
        <b>Your Wallet Botswana App </b> to your bank account. Here’s how:
      </>
    ),
    points: {
      type: "ol",
      list: [
        <>
          <b>Open the App:</b> Launch the Your Wallet Botswana App on your
          smartphone.
        </>,
        <>
          <b>Navigate to Transfers:</b> Tap on the “Transfers” option in the
          main menu.
        </>,
        <>
          <b>Select Bank Transfer:</b> Choose “Bank Transfer” as your transfer
          method.
        </>,
        <>
          <b>Enter Bank Details:</b> Input your bank account details, including
          the account number and bank name.
        </>,
        <>
          <b>Enter the Amount:</b> Specify the amount you wish to transfer from
          your wallet to your bank account.
        </>,
        <>
          <b>Review and Confirm:</b> Double-check the details and tap “Confirm”
          to complete the transfer.
        </>,
      ],
    },
    afterPoints:
      "Your money will be transferred to your bank account, typically within a few business days, depending on your bank’s processing time.",
  },
  {
    ques: "Is Your Wallet Botswana App compatible with all types of devices?",
    ans: "Your Wallet Botswana app is currently available for Android devices. We're actively working on expanding our platform, and an iOS version will be released in the future.In the meantime, Android users can fully access the app's features and services.",
  },
  {
    ques: "How can I contact Customer Support if I encounter issues?",
    ans: "If you encounter any issues with the &quot;Your Wallet Botswana&quot; app, you can contact our Customer Support team through the following methods:",
    points: {
      type: "ol",
      list: [
        <>
          <b> In-App Support:</b> Navigate to the &quot;Help&quot; or
          &quot;Support&quot; section within the app, where you can find FAQs
          and submit a support request directly.
        </>,
        <>
          <b> Email:</b> Send an email detailing your issue to our support team
          at support@yourwalletbw.co.bw. Make sure to include any relevant
          information such as your device type, operating system, and a
          description of the problem.
        </>,
        <>
          <b> Phone Support:</b> Call our Customer Support hotline at +267 [Your
          Phone Number]. Our team is available during business hours to assist
          you.
        </>,
        <>
          <b> Social Media:</b> Reach out to us via our official social media
          channels. We&#39;re responsive on platforms like Facebook, Twitter,
          and Instagram.
        </>,
      ],
    },
  },
];
