import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import React, { useRef, useState } from 'react'
import CustomTextField from '../ywbDesignSystem/textField';
import CustomButton from '../ywbDesignSystem/button';

const Otp = ({ otpError = '', btnTxt, submitBtn, disabled = false }) => {
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const otpBoxReference = useRef([]);

  const handleChange = (value, index) => {
    if (value.length <= 1) {
      let newArr = [...otp];
      newArr[index] = value;
      setOtp(newArr);
      if (value && index < 3) {
        otpBoxReference.current[index + 1].focus()
      }
    }
  }

  const handleBackspaceAndEnter = (e, index) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus()
    }
    if (e.key === "Enter" && e.target.value && index < 3) {
      otpBoxReference.current[index + 1].focus()
    }
  }

  return (
    <Box component='form' display='flex' flexDirection='column' gap={2} mt={2.5} noValidate autoComplete='off'>
      <Grid container md={12} gap={1} xs={12}  justifyContent='center' className='otp-container'>
        {otp.map((digit, index) => (
          <Grid md={1.5} xs={1.5} key={index}>
            <CustomTextField
              placeholder='-'
              value={digit}
              type='number'
              inputRef={(reference) => (otpBoxReference.current[index] = reference)}
              onChange={(e) => handleChange(e.target.value, index)}
              onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  display: "none",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
                input: {
                  textAlign: 'center'
                }
              }}
            />
          </Grid>
        ))}
      </Grid>
      {otpError && <Typography color={'#d32f2f'}>{otpError}</Typography>}
      <CustomButton name={btnTxt} variant='contained' color='secondary' onClick={() => submitBtn(otp)} disabled={disabled} style={{ height: '40px' }} />
    </Box>
  )
}

export default Otp