import React from "react";
import { CircularProgress } from "@mui/material";

const LoadingFallback = () => {
  return (
    <div
      style={{
        background: "rgba(255, 255, 255, 0.8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <CircularProgress color="success" />
    </div>
  );
};
export default LoadingFallback;
