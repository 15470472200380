import { Box, Typography } from '@mui/material'
import React from 'react'
import { keyAdvantages } from './homePageCompUtils'
import MediaQuery from 'react-responsive'

export const KeyAdvantages = () => {

  const renderKeyAdvantage = () => {
    return keyAdvantages.map(adv => {
      const { id, title, description, image } = adv
      return <Box id={id} key={id}  className='key-adv-box'>
        <img src={image} alt={title} className='key-adv-img'/>
        <Typography mt={2} className='key-adv-title'>{title}</Typography>
        <Typography color={'#888888'}>{description}</Typography>
      </Box>
    })
  }

  const renderContent = () => <>
    <Typography className='head'>Key Advantages of Our E-Wallet Services</Typography>
    <Typography className='sub-head' mb={7}>Easy, Fast, and Safe: Reasons to Opt for Our Services</Typography>
    <Box className='key-adv-wrapper'>
      {renderKeyAdvantage()}
    </Box>
  </>

  return (<>
    <MediaQuery maxWidth={768}>
      <Box className="key-advantages key-advantages__mob" display={'flex'} flexDirection='column' alignItems={'center'}>
        {renderContent()}
      </Box>
    </MediaQuery>
    <MediaQuery minWidth={769}>
      <Box className="key-advantages key-advantages__web" display={'flex'} flexDirection='column' alignItems={'center'}>
        {renderContent()}
      </Box>
    </MediaQuery>
  </>
  )
}
