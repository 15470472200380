import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "../../ywbDesignSystem/button";
import allTransaction from "../../assets/images/all-transaction-img.png";
import mAllTransaction from "../../assets/images/m-all-transaction-img.png";
import GetTheApp from "../sigInSignUp/getTheApp";
import MediaQuery from "react-responsive";

export const Transactions = () => {
  const [showModal, setModal] = useState(false);

  const handleGetTheAppPopup = () => {
    setModal(!showModal);
  };

  const renderContent = () => (
    <Box
      display={"flex"}
      gap={1}
      flexDirection={"column"}
      justifyContent={"center"}
    >
      <Typography fontWeight={500}>
        Anytime, anywhere, with Your Wallet Botswana
      </Typography>
      <Typography variant="h4" fontWeight={700} className="head">
        Easily handle all transactions on your mobile.
      </Typography>
      <Typography color="#888888">
        Your Wallet is a pioneering fintech company dedicated to revolutionising
        the payment landscape in Botswana. Established with the vision to
        provide a robust, secure, and efficient payment gateway, Your Wallet is
        committed to enabling seamless transactions for individuals, businesses,
        and merchants across the country.
      </Typography>
      <Box className="get-the-app-cta">
        <CustomButton
          name="Download the App"
          variant="contained"
          color="success"
          onClick={handleGetTheAppPopup}
        />
      </Box>
    </Box>
  );

  return (
    <>
      <MediaQuery maxWidth={768}>
        <Box className="all-transaction all-transaction__mob">
          {renderContent()}
          <Box>
            <img src={mAllTransaction} alt={"transaction 1"} width={"100%"} />
          </Box>
        </Box>
      </MediaQuery>
      <MediaQuery minWidth={769}>
        <Box
          display={"flex"}
          gap={2}
          className="all-transaction all-transaction__web"
        >
          {renderContent()}
          <Box>
            <img src={allTransaction} alt={"transaction  2"} />
          </Box>
        </Box>
      </MediaQuery>
      {showModal && <GetTheApp handlePopup={handleGetTheAppPopup} />}
    </>
  );
};
