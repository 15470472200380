import { Box, Paper, Typography } from '@mui/material'
import Carousel from 'react-multi-carousel'
import React, { useState } from 'react'
import { testimonals } from './homePageCompUtils'
import star from '../../assets/images/star.svg'
import filledStar from '../../assets/images/filledStar.svg'
import "react-multi-carousel/lib/styles.css";
import MediaQuery from 'react-responsive'

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4.25,
  },
  tablet: {
    breakpoint: { max: 1024, min: 769 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1.05,
  }
};

export const Testimonal = () => {

  const [testimonalsData] = useState(testimonals)

  const CustomDot = ({ onClick, ...rest }) => {
    const {
      active,
    } = rest;

    return (
      <button
        className={`custom-dot ${active ? "active" : "inactive"}`}
        onClick={() => onClick()}
      >
      </button>
    );
  };

  const renderTestimonals = () => {
    return <Carousel responsive={responsive} containerClass="carousel-container" itemClass="carousel-item" showDots={true} arrows={false} partialVisible={false} customDot={<CustomDot />}>
      {testimonalsData.map(testimonal => {
        const { id, author, rating, image, description } = testimonal
        return <Paper key={id} className='carousel-item-paper' >
          <Typography>{description}</Typography>
          <Box display={'flex'} >
            <img src={image} alt={author} width={40} height={40} style={{ borderRadius: '40px', marginRight: '15px' }} />
            <Box>
              <Typography>{author}</Typography>
              {Array(5).fill(0).map((starItm, index) => {
                let starType = star
                if (rating - 1 > index) {
                  starType = filledStar
                }
                return <img src={starType} alt='star' key={index} />
              })}
            </Box>
          </Box>
        </Paper>
      })
      }
    </Carousel>

  }

  const renderContents = () => <>
    <Typography className='head'>Testimonial</Typography>
    <Typography className='sub-head'>What Our Happy User Says</Typography>
    {renderTestimonals()}
  </>
  return (
    <>
      <MediaQuery maxWidth={768}>
        <Box className='testimonal testimonal__mob'>
          {renderContents()}
        </Box>
      </MediaQuery>
      <MediaQuery minWidth={769}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={2} className='testimonal testimonal__web'>
          {renderContents()}
        </Box>
      </MediaQuery>
    </>
  )
}
