export const URLS = {
  LOGIN: "v1/users/login",
  FORGOT_PASS: "v1/users/forgot-password",
  RESET_PASS_OTP: "v1/users/verify-reset-password-otp",
  RESET_PASS: "v1/users/reset-password",
  CONTACT_FORM:"v1/contact-us",
  COUNTRIES_LIST: "v1/country-state/countries",
  PROFILE: "v1/users/profile",
  NOTIFICATIONS: "v1/notifications",
  CHANGE_PASSWORD: "v1/users/change-password",
  TRANSACTIONS: "v1/transactions",
  PAYMENT_REQUESTS: "v1/transactions/payment-requests",
  LOGOUT: "v1/users/logout",
  LOOKUP: "v1/lookup/get-list",
  COMPANIES_PROFILE: "v1/companies/profile",
  DISPLAY_NAME: "v1/companies/display-settings",
  KYC: "v1/kyc/docs",
  COMPANY_ADDRESS: "v1/companies/address",
  SECRET_QUESTIONS: "v1/secret-questions",
  COMPANIES_BANK: "v1/companies/bank",
  USER_CARD: "v1/users/cards",
};
