import axios from "axios";

const getHost = () => {
  // console.log("===host", process.env);
  let host = "";
  switch (process.env.NODE_ENV) {
    case "development":
      host = "https://api-qa.yourwalletbw.com";
      break;
    default:
      host = "https://api-qa.yourwalletbw.com";
  }
  return host;
};

const expiredToken = () => {
  localStorage.clear();
  window.location.href = "/";
};

export const sendPostRequest = ({ url, data, isAuth = false }) => {
  let defaultHeaders = {};
  if (isAuth) {
    defaultHeaders =
      { Authorization: `Bearer ${localStorage.getItem("token")}` } || "";
  }
  const updatedConfig = {
    headers: {
      ...defaultHeaders,
    },
  };
  const finalUrl = `${getHost()}/${url}`;
  // console.log(finalUrl);
  return axios
    .post(finalUrl, data, updatedConfig)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        expiredToken();
      }
      throw err;
    });
};

export const sendUpdateRequest = ({ url, data, isAuth = false , isExpire = true }) => {
  let defaultHeaders = {};
  if (isAuth) {
    defaultHeaders =
      { Authorization: `Bearer ${localStorage.getItem("token")}` } || "";
  }
  const updatedConfig = {
    headers: {
      ...defaultHeaders,
    },
  };
  const finalUrl = `${getHost()}/${url}`;
  // console.log(finalUrl);
  return axios
    .put(finalUrl, data, updatedConfig)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 401 && isExpire === true) {
        expiredToken();
      }
      throw err;
    });
};

export const sendGetRequest = ({ url, isAuth = false }) => {
  const finalUrl = `${getHost()}/${url}`;
  let defaultHeaders = {};
  if (isAuth) {
    defaultHeaders =
      { Authorization: `Bearer ${localStorage.getItem("token")}` } || "";
  }
  const updatedConfig = {
    headers: {
      ...defaultHeaders,
    },
  };
  return axios
    .get(finalUrl, updatedConfig)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        expiredToken();
      }
      throw err;
    });
};
export const sendDeleteRequest = ({ url, isAuth = false, id }) => {
  const finalUrl = `${getHost()}/${url}/${id}`;
  let defaultHeaders = {};
  if (isAuth) {
    defaultHeaders =
      { Authorization: `Bearer ${localStorage.getItem("token")}` } || "";
  }
  const updatedConfig = {
    headers: {
      ...defaultHeaders,
    },
  };
  return axios
    .delete(finalUrl, updatedConfig)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        expiredToken();
      }
      throw err;
    });
};
