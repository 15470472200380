export const guestUserNavLinks = [
    {   
        user_type: '0' ,
        name: 'User Registration',
        path: '/user-registration'
    },
    {
        user_type:'0' ,
        name: 'Transaction Process',
        path: '/transaction-process'
    },
    {
        user_type:'0',
        name: 'Security Measures',
        path: '/security-measures'
    },
    {
        user_type:'0' ,
        name: 'Pricing & Billing',
        path: '/pricing-billing'
    },
    {
        user_type: '1' || '2' ,
        name: 'Merchant Guide',
        path: '/merchant-guide'
    },
    {  
       user_type: '2',
       name:'Verify My account documents',
       path:'/'
    },
    { 
       user_type: '2',
       name:'Reporting: Help grow your business ',
       path:'/'
    }


]