import { Box, Icon, InputAdornment, Modal, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import { basicSignInModalStyle, basicSignInModalStyleMob } from './popupUtils'
import signInPic from '../../assets/images/signInPic.png'
import CustomTextField from '../../ywbDesignSystem/textField'
import CustomButton from '../../ywbDesignSystem/button'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
// import { GoogleLogin } from '@react-oauth/google';
import './signInUp.scss';
import MediaQuery from 'react-responsive'
import { sendPostRequest } from '../../services/common'
import { URLS } from '../../services/urls'
import Title from './title'
import NewPassword from './newPassword'
import ResetPassword from './resetPassword'
import ForgotPassword from './forgotPassword'
import { useNavigate } from 'react-router-dom'

const SignIn = (props) => {
  const { handlePopup } = props
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState('')
  const [screen, setScreen] = useState('')
  const sendLoginReq = useRef(false)
  const formData = useRef({})
  const navigate = useNavigate()

  const handleScreen = ({ screen = '', formData = {} }) => {
    setFormData(formData)
    setScreen(screen)
  }

  const setFormData = (newFormData) => {
    formData.current = { ...formData.current, ...newFormData }
  }

  const handleEmailChange = (event) => {
    setError('')
    setEmail(event.target.value)
  }
  const handlePasswordChange = (event) => {
    setError('')
    setPassword(event.target.value)
  }

  const setShowPasswordChange = () => {
    setShowPassword(!showPassword)
  }

  const validateCredentials = async () => {
    console.log('validate')
    if (!sendLoginReq.current) {
      sendLoginReq.current = true
      try {
        const res = await sendPostRequest({ url: URLS.LOGIN, data: { email, password } })
        console.log(res)
        sendLoginReq.current = false
        if (res?.data?.data?.authToken?.access?.token) {
          localStorage.setItem('token', res.data.data.authToken.access.token)
          localStorage.setItem('user_type',res.data.data.user.user_type)
          localStorage.setItem('user',res.data.data.user.fname +"  "+res.data.data.user.lname)
          handlePopup()
          navigate('/dashboard')
        }
      }
      catch (err) {
        setError(err?.response?.data?.message || 'Something went wrong')
        sendLoginReq.current = false
      }
    }
  }
  const isDisabled = email && password ? false : true
  console.log("===isDisabled", isDisabled, sendLoginReq.current)

  const renderContent = () => {
    switch (screen) {
      case 'forgot-pass':
        return <ForgotPassword handlePopup={handlePopup} handleScreen={handleScreen} />
      case 'reset-pass':
        return <ResetPassword handlePopup={handlePopup} handleScreen={handleScreen} email={formData.current.email} />
      case 'new-pass':
        return <NewPassword handlePopup={handlePopup} handleScreen={handleScreen} token={formData.current.token} />
      default:
        return <Box p={3}>
          <Title title='Welcome Back' description='Sign In to your profile' handlePopup={handlePopup} />
          <Box display={'flex'} flexDirection={'column'} gap={2} mt={2.5}>
            <CustomTextField label='Email' required value={email} onChange={handleEmailChange} />
            <CustomTextField label='Password' required value={password} onChange={handlePasswordChange} type={showPassword ? 'text' : 'password'}
              autoComplete="new-password"
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <Icon
                    aria-label="toggle password visibility"
                    onClick={setShowPasswordChange}
                    edge="end"
                    className='cursor-pointer'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </Icon>
                </InputAdornment>
              }}>
            </CustomTextField>
            {error && <Typography color={'#DE3C24'}>{error}</Typography>}
            <Typography alignSelf={'end'} style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleScreen({ screen: 'forgot-pass' })}>Forgot Password?</Typography>
          </Box>
          <Box display='flex' flexDirection={'column'} gap={1} width={'100%'} mt={5}>
            <CustomButton name='Sign In' variant='contained' color='secondary' onClick={validateCredentials} disabled={isDisabled} style={{ height: '40px' }} />
            {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
          </Box>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={1} mt={3}>
            <Typography fontSize={'14px'}>
              Don't have an account yet?
            </Typography>
            <Box width={127}>
              <CustomButton name='Get the app' variant='contained' color='secondary' onClick={() => handlePopup('getTheApp')} style={{ height: '40px' }} />
            </Box>
          </Box>
        </Box>
    }
  }

  return (
    <Modal open={true} onClose={handlePopup}>
      <>
        <MediaQuery minWidth={769}>
          <Box sx={basicSignInModalStyle}>
            <Box display='flex'>
              <Box style={{width:'360px'}}>
                <img className='sign-in-pic' src={signInPic} alt='signIn' />
              </Box>
              {renderContent()}
            </Box>
          </Box>
        </MediaQuery>
        <MediaQuery maxWidth={768}>
          <Box sx={basicSignInModalStyleMob}>
            {renderContent()}
          </Box>
        </MediaQuery>
      </>
    </Modal>
  )
}

export default SignIn
