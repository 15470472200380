import React from 'react';
import { Box, Typography } from '@mui/material';
import MediaQuery from 'react-responsive';
import playStoreIcon from '../../assets/images/google-play.svg';
// import appStoreIcon from '../../assets/images/apple-store.svg';
import appImg from '../../assets/images/app-image.png';
import mAppImg from '../../assets/images/m-app-image.png';


export const TopBanner = () => {

    const renderContent = () => <Box className='top-banner_content'>
        <Typography className="sub-head">Welcome to an Innovative Financial Experience</Typography>
        <Typography className="head">Simplify the management of your Personal Finances</Typography>
        <Typography className="text">Enhance your financial journey with Your Wallet Botswana. We streamline payment processes, facilitate money transfers, and empower you in managing your finances, providing complete control over your money.</Typography>
        <Box>
            <img src={playStoreIcon} alt='playstore' style={{ paddingRight: '12px' }} />
            {/* <img src={appStoreIcon} alt='appStore' /> */}
        </Box>
    </Box>

    return (
        <>
            <MediaQuery maxWidth={768}>
                <Box className="top-banner top-banner__mob">
                    <Box display={'flex'} flexDirection={'column'} gap={3}>
                        {renderContent()}
                        <Box className="">
                            <img src={mAppImg} alt='mob img' width={'100%'}/>
                        </Box>
                    </Box>
                </Box>

            </MediaQuery>
            <MediaQuery minWidth={769}>
                <Box className="top-banner top-banner__web">
                    <Box display={'flex'} justifyContent={'space-between'}>
                        {renderContent()}
                        <Box className="app-image">
                            <img src={appImg} alt='mob img' />
                        </Box>
                    </Box>
                </Box>
                <Box className="elips-shapes" />
            </MediaQuery>
        </>
    )
}
