import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import Title from './title'
import CustomButton from '../../ywbDesignSystem/button'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { sendUpdateRequest } from '../../services/common'
import { URLS } from '../../services/urls'
import Password from '../password'

const NewPassword = ({ handlePopup, handleScreen, token }) => {
  const [formData, setFormData] = useState({ password: '', confirmPassword: '' })
  const [error, setError] = useState('')

  const handleDataChange = (event) => {
    setError('')
    const { name, value } = event.target
    console.log(name, value)
    setFormData({ ...formData, [name]: value })
  }

  const handleNewPassCreation = async () => {
    try {
      const { password, confirmPassword } = formData
      if (password === confirmPassword) {
        const response = await sendUpdateRequest({ url: URLS.RESET_PASS, data: { password, confirm_password: confirmPassword, token } })
        console.log(response)
        handleScreen({})
      }
      else {
        setError('Password and Confirm Password must be same')
      }
    }
    catch (err) {
      setError(err?.response?.data?.message || 'Something went wrong')
    }
  }

  return (
    <Box p={3}>
      <Title title='Create new password' description='Fill all the form below to create your new password' handlePopup={handlePopup} />
      <Grid md={6.1} pt={2}>
        <Box component='form' display='flex' flexDirection='column' gap={2} noValidate autoComplete='off'>
          <Password label='Password' password={formData.password} handleDataChange={handleDataChange} />
          <Password label='Confirm Password' name='confirmPassword' password={formData.confirmPassword} handleDataChange={handleDataChange} />
          {error && <Typography color={'#d32f2f'}>{error}</Typography>}
          <CustomButton name={'Next'} variant='contained' color='secondary' onClick={handleNewPassCreation} style={{ height: '40px' }} />
        </Box>
      </Grid>
    </Box>
  )
}

export default NewPassword