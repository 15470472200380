import { Box, Container, Grid, Modal, Typography } from '@mui/material'
import React, { useState } from 'react'
import { basicModalStyle, basicSignInModalStyleMob } from './popupUtils';
import getAppScanner from '../../assets/images/getAppScanner.svg'
import CustomTextField from '../../ywbDesignSystem/textField';
import CustomButton from '../../ywbDesignSystem/button';
import playStoreIcon from '../../assets/images/google-play.svg';
// import appStoreIcon from '../../assets/images/apple-store-small.png';
// import appGallery from '../../assets/images/appGallery.png';
import crossIcon from '../../assets/images/crossIcon.svg';
import MediaQuery from 'react-responsive';
import SelectCountryCode from '../selectCountryCode';
const GetTheApp = (props) => {
    const { handlePopup } = props
    const [formData, setFormData] = useState({ countryCode: '', mobNo: '' })
    const handleChange = ({ name, value }) => {
        setFormData({ ...formData, [name]: value })
    }

    const handleMobNoChange = (event) => {
        const { value } = event.target
        handleChange({ name: 'mobNo', value })
    }
    const handleCountryCodeChange = (event) => {
        const { value } = event.target
        handleChange({ name: 'countryCode', value })
    }

    const renderTextData = () => {
        return <>
            <Box alignSelf='end' className='cross-icon'>
                <img src={crossIcon} alt='cross icon' onClick={handlePopup} className='cursor-pointer' />
            </Box>
            <Typography className='font-weight-600' variant='h5'>
                Download the app with a simple snap...
            </Typography>
            <Typography pt={2} pb={2}>
                Simply scan the QR Code on your left, or manually enter your mobile number below to get the download link via SMS
            </Typography>
        </>
    }
    const renderContent = () => {
        return <>
            <Grid container spacing={1.5}>
                <Grid item xs={4} md={3}>
                    <SelectCountryCode sx={{height:'3rem',paddingTop:'1px'}}  countryCode={formData.countryCode} handleCountryCodeChange={handleCountryCodeChange} />
                </Grid>
                <Grid item xs={8} md={6}>
                    <CustomTextField label={'Your Device Number'} required value={formData.mobNo} onChange={handleMobNoChange} style={{ width: '100%' }} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <CustomButton name='Get Link' variant='contained' color='success' />
                </Grid>

            </Grid>
            <div className="app-actions d-flex m-t-15">
                <img style={{height:'3rem'}} src={playStoreIcon} alt='playstore' />
                {/* <img src={appStoreIcon} alt='appStore' /> */}
                {/* <img my={1} src={appGallery} alt='appGallery' /> */}
            </div>
        </>
    }
    return (
        <Modal open={true} onClose={handlePopup}>
            <Container>
                <MediaQuery maxWidth={768}>
                    <Box sx={basicSignInModalStyleMob} className='get-app get-app__mob'>
                        <Box className='main-wrapper' gap={1}>
                            {renderTextData()}
                            <Box alignSelf={'center'}>
                                <img src={getAppScanner} alt='app scanner' />
                            </Box>
                            {renderContent()}
                        </Box>
                    </Box>
                </MediaQuery>
                <MediaQuery minWidth={769}>
                    <Box sx={basicModalStyle} pt={6} pb={6} pl={3} pr={3} className='get-app get-app__web'>
                        <Box className='main-wrapper'>
                            <Box alignSelf={'center'}>
                                <img src={getAppScanner} alt='app scanner' />
                            </Box>
                            <Box className='p-l-45' width={460} display={'flex'} flexDirection={'column'}>
                                {renderTextData()}
                                {renderContent()}
                            </Box>
                        </Box>
                    </Box>
                </MediaQuery>
            </Container>
        </Modal>
    )
}

export default GetTheApp
