
import React from 'react'
import Button from '@mui/material/Button'
import { ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      // Name of the slot
      variants: [
        {
          props: { variant: 'contained', color: 'success' },
          style: {
            backgroundColor: '#5B8828',
            textTransform: 'none',
            height: '48px',
            color: '#fff',
            width: '100%',
            '&:hover': {
              backgroundColor: '#75A83A'
            },
            '&:active': {
              backgroundColor: '#466625'
            },
            '&:focus': {
              backgroundColor: '#466625'
            },
            '&:disabled': {
              backgroundColor: '#A5C48B',
              color: '#fff'
            }
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            backgroundColor: '#131313',
            textTransform: 'none',
            height: '48px',
            color: '#fff',
            width: '100%',
           '&.Mui-disabled': {
              backgroundColor: 'rgba(0,0,0,0.25)',
              color: 'white',
            },
            '&:hover': {
              backgroundColor: '#3D3D3D'
            },
            '&:active': {
              backgroundColor: '#5D5D5D'
            },
            '&:focus': {
              backgroundColor: '#5D5D5D'
            }
            
            
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            backgroundColor: '#FFFFFF',
            borderColor: '#000000',
            textTransform: 'none',
            height: '48px',
            color: '#000000',
            width: '100%',
            '&:hover': {
              backgroundColor: '#FFFFFF'
            },
            '&:active': {
              backgroundColor: '#FFFFFF'
            },
            '&:focus': {
              backgroundColor: '#FFFFFF'
            },
            
          },
        },
      ],
    },
  },
});

const CustomButton = (props) => {
  const { name, ...restProps } = props
  return <ThemeProvider theme={theme}>
    <Button {...restProps} >{name}</Button>
  </ThemeProvider>
}

export default CustomButton
