import React, { useMemo } from "react";
import { Box, Button, Typography } from "@mui/material";
import LogoutIcon from "../../../assets/images/logout.svg";
import ProfileImg from "../../../assets/images/testimonalPic1.png";
import { useNavigate } from "react-router-dom";
import { sendUpdateRequest } from "../../../services/common";
import { URLS } from "../../../services/urls";

const ProfileLogoutTab = () => {
  const navigate = useNavigate();
  const userName = useMemo(() => localStorage.getItem("user"), []);

  const handleLogout = (e) => {
    e.stopPropagation();
    logout();
    localStorage.clear();
    navigate("/");
  };
  const logout = async () => {
    try {
      const response = await sendUpdateRequest({
        url: URLS.LOGOUT,
        isAuth: true,
      });
      console.log(response);
    } catch (err) {
      console.log(err);
      // Handle error: e.g., show error message to user
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      p={1}
      py={2}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <img
            src={ProfileImg}
            style={{ borderRadius: "50px" }}
            alt="Profile"
          />
        </Box>
        <Box>
          <Typography sx={{ fontSize: "0.8rem" }} px={2}>
            {userName}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Button onClick={handleLogout}>
          <img src={LogoutIcon} alt="Logout Icon" />
        </Button>
      </Box>
    </Box>
  );
};

export default ProfileLogoutTab;
