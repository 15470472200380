import { Box } from '@mui/material'
import React from 'react'
import MediaQuery from 'react-responsive'
import { Faq } from '.'

const FaqWrapper = (props) => {
  return (
    <>
      <MediaQuery maxWidth={768}>
        <Box className='faq-wrapper faq-wrapper__mob'><Faq className='faq' {...props} /></Box>
      </MediaQuery>
      <MediaQuery minWidth={769}>
        <Box className='faq-wrapper faq-wrapper__web'><Faq className='faq' {...props} /></Box>
      </MediaQuery>
    </>
  )
}

export default FaqWrapper