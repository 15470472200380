import * as React from "react";
import Box from "@mui/material/Box";
import ywbLogo from "../../../assets/images/ywb-logo.svg";
import "./sideNavigation.scss";
import MediaQuery from "react-responsive";
import { AppBar } from "@mui/material";
import MobNav from "./MobNav";
import WebNav from "./WebNav";
import { useNavigate } from "react-router-dom";

const SideNavigation = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <MediaQuery maxWidth={768}>
        <AppBar className="navBarDash_mob">
          <Box p={2}>
            <img src={ywbLogo} alt="ywb Logo" className="cursor-pointer" onClick={()=>navigate('/')} />
          </Box>
          <MobNav />
        </AppBar>
      </MediaQuery>
      <MediaQuery minWidth={769}>
        <Box sx={{ width:'25%' }}>
            <WebNav />
        </Box>
      </MediaQuery>
    </>
  );
};

export default SideNavigation;
