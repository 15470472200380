import { Box, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import Title from './title'
import CustomTextField from '../../ywbDesignSystem/textField'
import CustomButton from '../../ywbDesignSystem/button'
import { sendUpdateRequest } from '../../services/common'
import { URLS } from '../../services/urls'

const ForgotPassword = ({ handlePopup, handleScreen }) => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const isDisabled = useRef(false)

  const handleDataChange = (event) => {
    setError('')
    setEmail(event.target.value)
  }

  const validateCredentials = async () => {
    isDisabled.current = true
    try {
      await sendUpdateRequest({ url: URLS.FORGOT_PASS, data: { email } })
      handleScreen({ screen: 'reset-pass', formData: { email } })
    }
    catch (err) {
      setError(err?.response?.data?.message || 'Something went wrong')
    }
    finally {
      isDisabled.current = false
    }
  }

  return (
    <Box p={3}>
      <Title title='Forgot Password' description='Enter the email address associated with your account to receive reset link' handlePopup={handlePopup} />
      <Box display={'flex'} flexDirection={'column'} gap={2} mt={2.5}>
        <CustomTextField label='Email' required value={email} onChange={handleDataChange} />
        {error && <Typography color={'#DE3C24'}>{error}</Typography>}
        <CustomButton name='Sign In' variant='contained' color='secondary' onClick={validateCredentials} disabled={isDisabled.current} style={{ height: '40px' }} />
      </Box>
    </Box>
  )
}

export default ForgotPassword