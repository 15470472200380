import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import "./index.scss";
import "./container/homepage/homePage.scss";
import LoadingFallback from "./components/common/LodingFallback";
// import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    {/* <CommonStore> */}
    <Suspense fallback={<LoadingFallback />}>
      {/* <GoogleOAuthProvider clientId="<your_client_id>"> */}
      <RouterProvider router={router}></RouterProvider>
      {/* </GoogleOAuthProvider> */}
    </Suspense>
    {/* </CommonStore> */}
  </React.StrictMode>
);
