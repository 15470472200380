export const footerLinks = [
    {
        title: 'COMPANY',
        children: [
            {
                name: 'About Us',
                path: '/about-us'
            },
            {
                name: 'Our Team',
                path: ''
            },
            {
                name: 'Contact Us',
                path: '/contact-us'
            },
            {
                name: 'Blog',
                path: ''
            }
        ]
    },
    {
        title: 'SUPPORT',
        children: [
            {
                name: 'Getting Started',
                path: ''
            },
            {
                name: 'Privacy Policy',
                path: '/privacy-policy'
            },
            {
                name: 'Cookies Page',
                path: '/cookies'
            },
            {
                name: 'Terms & Conditions',
                path: '/terms-conditions'
            },
            {
                name: 'Help Center',
                path: '/support'
            },
            {
                name: 'Server Status',
                path: ''
            },
            {
                name: 'Report a Bug',
                path: ''
            },
            {
                name: 'Chat Support',
                path: ''
            },

        ]
    },
    {
        title: 'PRODUCTS',
        children: [
            {
                name: 'Features',
                path: ''
            },
            {
                name: 'Review',
                path: ''
            },
            {
                name: 'Update',
                path: ''
            }
        ]
    },
    {
        title: 'DOWNLOADS',
        children: [
            {
                name: 'iOS',
                path: ''
            },
            {
                name: 'Android',
                path: ''
            }
        ]
    }
]