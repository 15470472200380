import { Box } from '@mui/material'
import React, { useState } from 'react'
import Title from './title'
import { URLS } from '../../services/urls'
import { sendUpdateRequest } from '../../services/common'
import Otp from '../otp'

const ResetPassword = ({ handlePopup, email, handleScreen }) => {
  const [otpError, setOtpError] = useState(null)
  const [disabled, setDisabled] = useState(false)

  const handleResetBtn = async (otp) => {
    try {
      setDisabled(true)
      const finalOtp = otp.join('')
      if (finalOtp.length ===4) {
        const response = await sendUpdateRequest({ url: URLS.RESET_PASS_OTP, data: { email, otp: Number(finalOtp) } , isExpire: false })
        const token = response?.data?.data.token
        console.log(token)
        handleScreen({ screen: 'new-pass', formData: { token } })
      }
      else {
        setOtpError('OTP must be 4 digits long')
      }
    }
    catch (err) {
      setOtpError(err?.response?.data?.message || 'Something went wrong')
    }
    finally {
      setDisabled(false)
    }
  }

  return (
    <Box p={3}>
      <Title title='Reset password' description='Enter the 4-digit verification code that was sent to your email to change your password.' handlePopup={handlePopup} />
      <Otp btnTxt='Next' submitBtn={handleResetBtn} otpError={otpError} disabled={disabled} />
    </Box>
  )
}

export default ResetPassword