import testimonalspic1 from '../../assets/images/testimonalPic1.png'
import testimonalspic2 from '../../assets/images/testimonalPic2.png'
import testimonalspic3 from '../../assets/images/testimonalPic3.png'
import testimonalspic4 from '../../assets/images/testimonalPic4.png'
import kaIcn1 from '../../assets/images/ka-icon-1.svg'
import kaIcn2 from '../../assets/images/ka-icon-2.svg'
import kaIcn3 from '../../assets/images/ka-icon-3.svg'
import kaIcn4 from '../../assets/images/ka-icon-4.svg'
import cLogo1 from '../../assets/images/YM_1.png'
import cLogo2 from '../../assets/images/YM_2.png'
import cLogo3 from '../../assets/images/YM_3.png'



export const testimonals = [
  {
    id: 1,
    author: 'Putriana Sari',
    image: testimonalspic1,
    rating: 5,
    description: "I really like this e-wallet app because it's incredibly convenient. I can make payments with just a few taps on my phone. It's very user-friendly, even for people who aren't very tech-savvy.",
  },
  {
    id: 2,
    author: 'Farhan Ibrahim',
    image: testimonalspic2,
    rating: 5,
    description: "I feel secure using this e-wallet app. They have multiple layers of security, including two-factor authentication, so I never worry about my data or money being compromised.",
  },
  {
    id: 3,
    author: 'Rizky Firmansyah',
    image: testimonalspic3,
    rating: 4,
    description: "I once encountered an issue with my transaction, but the customer support team from this e-wallet app was extremely helpful. They quickly resolved my problem and provided clear guidance.",
  },
  {
    id: 4,
    author: 'Melati Wulandari',
    image: testimonalspic4,
    rating: 3,
    description: "This e-wallet app isn't limited to just one payment method. They support various methods, including credit cards, debit cards, and bank transfers, so I can choose the most convenient way for me.",
  },
  {
    id: 5,
    author: 'test1',
    image: '',
    rating: 4,
    description: "testing1",
  },
  {
    id: 6,
    author: 'test2',
    image: '',
    rating: 0,
    description: "testing 2",
  }
]

export const keyAdvantages = [
  {
    id: 1,
    title: 'Quick & Reliable Transactions',
    description: 'Enjoy secure and instant payments anywhere, anytime. YWB gives you peace of mind.',
    image: kaIcn1
  },
  {
    id: 2,
    title: '24 / 7 Customer Support',
    description: "Our team is ready to assist you anytime. If you have questions or issues, don't hesitate to contact us.",
    image: kaIcn2
  },
  {
    id: 3,
    title: 'Exclusive Promos and Discounts',
    description: 'Exclusive app promos, discounts, cashback; save, feel benefits in your wallet.',
    image: kaIcn3
  },
  {
    id: 4,
    title: 'Easy Financial Management',
    description: 'Effortlessly manage finances, track expenses, set budgets, and invest with our e-wallet app.',
    image: kaIcn4
  }
]

export const companies = [
  {
    id: 1,
    name: 'comapny1',
    image: cLogo1
  },
  {
    id: 2,
    name: 'comapny2',
    image: cLogo2
  },
  {
    id: 3,
    name: 'comapny3',
    image: cLogo3
  },
]