import React, { useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { FormGroup, Icon, InputAdornment, InputLabel } from '@mui/material'
import CustomTextField from '../ywbDesignSystem/textField'

const Password = ({ label, password, handleDataChange, name = 'password', error = false, helperText = '' ,required = true,placeholder='Enter your Password'}) => {

  const [showPassword, setShowPassword] = useState(false)

  const setShowPasswordChange = () => {
    setShowPassword(!showPassword)
  }

  return (
    <FormGroup>
      <InputLabel required={required}> {label} </InputLabel>
      <CustomTextField
        name={name}
        placeholder={placeholder}
        required={required}
        onChange={handleDataChange}
        type={showPassword ? 'text' : 'password'}
        value={password}
        error={error}
        helperText={helperText}
        InputProps={{
          endAdornment: <InputAdornment position="end">
            <Icon
              aria-label="toggle password visibility"
              onClick={setShowPasswordChange}
              edge="end"
              className='cursor-pointer'
            >
              {showPassword ? <VisibilityOff style={{ color: '#667085' }} /> : <Visibility style={{ color: '#667085' }} />}
            </Icon>
          </InputAdornment>
        }}
      />
    </FormGroup>
  )
}

export default Password