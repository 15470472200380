import { lazy } from "react";
import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import HomePage from "./container/homepage";
import SideNavigation from "./components/securedPages/sideNavigation/index";
import { Box } from "@mui/material";

const UserRegistration = lazy(() => import("./container/userRegistration"));
const TransactionProcess = lazy(() => import("./container/transactionProcess"));
const SecurityMeasures = lazy(() => import("./container/securityMeasures"));
const PricingBiling = lazy(() => import("./container/pricingBilling"));
const MerchantGuide = lazy(() => import("./container/merchantGuide"));
const Cookies = lazy(() => import("./container/staticPages/cookies"));
const PrivacyPolicy = lazy(() =>import("./container/staticPages/privacyPolicy"));
const TermsNConditions = lazy(() =>import("./container/staticPages/termsNConditions"));
const Support = lazy(() => import("./container/staticPages/support"));
const ContactUs = lazy(() => import("./container/staticPages/contactUs"));
const AboutUs = lazy(() => import("./container/staticPages/aboutUs"));
const Dashboard = lazy(() => import("./container/securedPages/dashboard"));
const TransactionHistory = lazy(() =>import("./container/securedPages/transactionHistory"));
const MyProfile = lazy(() => import("./container/securedPages/myProfile"));
const KycDocumentation = lazy(() =>import("./container/securedPages/kycDocumentation"));
const Settings = lazy(() => import("./container/securedPages/settings"));
const ManageSecurity = lazy(() =>import("./container/securedPages/ManageSecurity"));
const CompanyProfile = lazy(() => import("./container/securedPages/companyProfile"));
const PaymentRequestHistory = lazy(() => import("./container/securedPages/paymentRequestHistory"));
const Reports = lazy(() => import("./container/securedPages/reports"));
const Payout = lazy(() => import("./container/securedPages/payout"));
// const SupportDash = lazy(() => import("./container/securedPages/support"));

const isAuthenticated = () => {
    return localStorage.getItem("token");
};

const PrivateRoute = () => {
    return isAuthenticated() ? (
        <Box sx={{
            height: "100vh",
            overflow: "hidden"
        }}>
            <Box
                sx={{
                    display: "flex",
                    background: "linear-gradient(168.44deg, #207028 0%,#0b360f 100%)",
                }}
            >
                <SideNavigation />
                <Outlet />
            </Box>
        </Box>
    ) : (
        <Navigate to="/" />
    );
};

const CompanyRoute = () => {
    return (
        isAuthenticated() && localStorage.getItem('user_type') === '2' ? (
            <Box
                sx={{
                    height: "100vh",
                    overflow: "hidden"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        background: "linear-gradient(168.44deg, #207028 0%,#0b360f 100%)",
                    }}
                >
                    <SideNavigation />
                    <Outlet />
                </Box>
            </Box>
        ) : (
            <Navigate to="/" />
        )
    );
}

const UserRoute = () => {
    return (
        isAuthenticated() && localStorage.getItem('user_type') === '1' ? (
            <Box
                sx={{
                    height: "100vh",
                    overflow: "hidden"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        background: "linear-gradient(168.44deg, #207028 0%,#0b360f 100%)",
                    }}
                >
                    <SideNavigation />
                    <Outlet />
                </Box>
            </Box>
        ) : (
            <Navigate to="/" />
        )
    );
}




const router = createBrowserRouter([
    {
        key: "/",
        path: "/",
        element: <HomePage />
    },
    {
        path: "user-registration",
        Component: UserRegistration,
    },
    {
        path: "transaction-process",
        Component: TransactionProcess,
    },
    {
        path: "security-measures",
        Component: SecurityMeasures,
    },
    {
        path: "pricing-billing",
        Component: PricingBiling,
    },
    {
        path: "merchant-guide",
        Component: MerchantGuide,
    },
    {
        key: "cookies",
        path: "cookies",
        Component: Cookies,
    },
    {
        key: "privacy-policy",
        path: "privacy-policy",
        Component: PrivacyPolicy,
    },
    {
        key: "terms-conditions",
        path: "terms-conditions",
        Component: TermsNConditions,
    },
    {
        key: "support",
        path: "support",
        Component: Support,
    },
    {
        key: "contact-us",
        path: "contact-us",
        Component: ContactUs,
    },
    {
        key: "about-us",
        path: "about-us",
        Component: AboutUs,
    },
    {
        key: "dashboard",
        path: "dashboard",
        element: <PrivateRoute />,
        children: [
            {
                key: "dashboard",
                path: "",
                Component: Dashboard,
            },
            {
                key: "transaction-history",
                path: "transaction-history",
                Component: TransactionHistory,
            },
            {
                key: "settings",
                path: "settings",
                Component: Settings,
            },
            {
                key: "manage-security",
                path: "manage-security",
                Component: ManageSecurity,
            },
            {
                key: "payment-request-history",
                path: "payment-request-history",
                Component: PaymentRequestHistory,
            },
            {
                key: "reports",
                path: "reports",
                Component: Reports,
            },
            {
                key: "payout",
                path: "payout",
                Component: Payout,
            },
        ],
    },
    { key: "dashboard",
    path: "dashboard",
    element: <CompanyRoute />,
    children: [
        
            {
                key: "company-profile",
                path: "company-profile",
                Component: CompanyProfile,
            },
            {
                key: "kyc-documentation",
                path: "kyc-documentation",
                Component: KycDocumentation,
            },
        
    ],
},
    { 
    key: "dashboard",
    path: "dashboard",
    element: <UserRoute />,
    children: [
        {
            key: "my-profile",
            path: "my-profile",
            Component: MyProfile,
        },
        
    ],
    }
]);

export default router;
