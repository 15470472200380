import { Box } from '@mui/material'
import Carousel from 'react-multi-carousel'
import React from 'react'
import { companies } from './homePageCompUtils';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 769 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1.5,
  }
};

export const SupportBy = () => {

  const CustomDot = ({ onClick, ...rest }) => {
    const {
      active,
    } = rest;

    return (
      <button
        className={`custom-dot ${active ? "active" : "inactive"}`}
        onClick={() => onClick()}
      >
      </button>
    );
  };

  const renderCompanies = () => {
    return <Carousel responsive={responsive} containerClass="carousel-container" itemClass="carousel-item" showDots={true} arrows={false} partialVisible={false} customDot={<CustomDot />}>
      {companies.map(company => {
        const { id, image, name } = company
        return <img id={id} src={image} alt={name} key={id} />
      })}
    </Carousel>
  }
  return (
    <Box className="support-by">
      {/* <Typography fontSize={'1.25rem'} fontWeight={600} mb={2}>Support By</Typography> */}
      {renderCompanies()}
    </Box>
  )
}
