export const basicModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: 720,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '16px',
}
export const basicSignInModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: 720,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '30px',
    height: '464px'
}

export const basicSignInModalStyleMob = {
    position: 'absolute',
    bottom: 0,
    left: 0,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderTopLeftRadius: '30px',
    borderTopRightRadius: '30px',
    width: '100%',
}