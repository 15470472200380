import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "../../ywbDesignSystem/button";
import paymentMethodImg from "../../assets/images/payment-methods-img.svg";
import mPaymentMethodImg from "../../assets/images/m-payment-methods-img.svg";
import MediaQuery from "react-responsive";

export const PaymentMethods = () => {
  const [showModal, setModal] = useState(false);

  const handleTalkToUs = () => {
    setModal(!showModal);
  };

  const renderContent = () => {
    return (
      <Box display={"flex"} gap={4} flexDirection={"column"} flex={0.8}>
        <Typography variant="h4" className="head">
          Different payment methods to choose from
        </Typography>
        <Typography style={{ fontSize: "18px" }} color={"#888888"}>
          With a strong emphasis on security, reliability, and customer
          satisfaction, Your Wallet aims to become the leading payment gateway
          in Botswana. Our digital payment solution is the first of its kind,
          proudly produced and dedicated to Botswana.
        </Typography>

        <Box className="talk-to-us-cta">
          <CustomButton
            name="Talk to Us"
            variant="contained"
            color="success"
            onClick={handleTalkToUs}
          />
        </Box>
      </Box>
    );
  };
  return (
    <>
      <MediaQuery maxWidth={768}>
        <Box className="payment-methods payment-methods__mob">
          {renderContent()}
          <Box>
            <img src={mPaymentMethodImg} alt="payment-methods" width={"100%"} />
          </Box>
        </Box>
      </MediaQuery>
      <MediaQuery minWidth={768}>
        <Box display={"flex"} className="payment-methods payment-methods__web">
          <Box flex={0.4}>
            <img src={paymentMethodImg} alt="payment-methods" />
          </Box>
          {renderContent()}
        </Box>
      </MediaQuery>
    </>
  );
};
