import React from 'react';
import { NavLink } from 'react-router-dom';
import youTubeImg from '../assets/images/s-icon-youtube.svg';
import whatsappImg from '../assets/images/s-icon-whasapp.svg';
import twitterImg from '../assets/images/s-icon-twitter.svg';
import instaImg from '../assets/images/s-icon-instagram.svg';
import fbImg from '../assets/images/s-icon-facebook.svg';

const SocialLinks = () => {
    return (
        <div className="social">
            <NavLink to=''><img src={youTubeImg} alt='youtube icon' /></NavLink>
            <NavLink to=''><img src={whatsappImg} alt='whatsapp icon' /></NavLink>
            <NavLink to=''><img src={fbImg} alt='fb icon' /></NavLink>
            <NavLink to=''><img src={twitterImg} alt='twitter icon' /></NavLink>
            <NavLink to=''><img src={instaImg} alt='insta icon' /></NavLink>
        </div>
    )
}

export default SocialLinks
