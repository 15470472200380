import { Box, Modal, Typography } from '@mui/material'
import React, { useState } from 'react'
import { basicModalStyle, basicSignInModalStyleMob } from './popupUtils'
import CustomTextField from '../../ywbDesignSystem/textField'
import CustomButton from '../../ywbDesignSystem/button'
import SocialLinks from '../socialLinks'
import crossIcon from '../../assets/images/crossIcon.svg'
import MediaQuery from 'react-responsive'
const ShareWithFrnd = (props) => {
  const { handlePopup } = props
  const [email, setEmail] = useState('')
  const [copyText, setCoptText] = useState('Copy Link')
  const handleEmailChange = (event) => {
    setEmail(event.target.value)
    if (copyText !== 'Copy Link') {
      setCoptText('Copy Link')
    }
  }

  const copyTextToClipboard = () => {
    navigator.clipboard.writeText(email)
    setCoptText('Copied!')
  }

  const renderContent = () => {
    return <>
      <Box display="flex" justifyContent='end' className='cross-icon'>
        <img src={crossIcon} alt='cross icon' onClick={handlePopup} className='cursor-pointer' />
      </Box>
      <Box>
        <Typography variant='h6' className='font-weight-600'>Tell your close ones about Your Wallet Botswna App</Typography>
      </Box>
      <Box className='m-t-25 m-b-25'>
        <Typography variant='p'>Copy the link below and send it to your friends and family,or share it to your channels</Typography>
      </Box>
      <Box gap={2} className='share-app__email-div m-b-25'>
        <CustomTextField placeholder='Enter your email id' value={email} onChange={handleEmailChange} className='share-app__email-div__email' />
        <CustomButton variant='contained' color='success' name={copyText} onClick={copyTextToClipboard} className='share-app__email-div__button' />
      </Box>
      <SocialLinks />
    </>
  }
  return (
    <Modal open={true} onClose={handlePopup}>
      <>
        <MediaQuery minWidth={769}>
          <Box sx={basicModalStyle} className='share-app share-app__web' p={3}>
            {renderContent()}
          </Box>
        </MediaQuery>
        <MediaQuery maxWidth={768}>
          <Box sx={basicSignInModalStyleMob} className='share-app share-app__mob'>
            {renderContent()}
          </Box>
        </MediaQuery>
      </>
    </Modal>
  )
}

export default ShareWithFrnd
