import { Box, ThemeProvider, Typography, createTheme } from '@mui/material'
import React from 'react'
import { SingleFaqQues } from './singleFaqQues'

const theme = createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '@media (min-width: 769px)': {
            border: 'none',
            boxShadow: 'none',
            width: '768px',
            margin: 0,
            borderBottom: '1px solid #D1D1D1',
            borderRadius: 0
          },
          '@media (max-width: 768px)': {
            paddingBottom: '10px',
            paddingTop: '10px'
          },
          borderRadius: '0.375rem',
          '&::before': {
            backgroundColor: '#ffffff',
          },
          '&:last-of-type': {
            borderBottom: 'none'
          },
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          margin: '-10px 0 0 0',
          '&.Mui-expanded': {
            margin: 0
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingTop: '0px'
        }
      }
    }
  }
})
export const Faq = (props) => {
  const { className = '', faqQues, title, subTitle } = props
  const renderFaqQues = () => {
    return faqQues.map((quesItm, index) => (<SingleFaqQues key={quesItm.ques} quesItm={quesItm} defaultExpanded={index === 0} />))
  }
  return (
    <ThemeProvider theme={theme}>
      <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} alignItems={'center'} gap={2} className={className}>
        <Typography variant='h4' className='head'>{title}</Typography>
        <Typography className='sub-head'>{subTitle}</Typography>
        {renderFaqQues()}
      </Box>
    </ThemeProvider>
  )
}
