import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import MediaQuery from "react-responsive";
import ywbLogo from "../../assets/images/ywb-logo.svg";
import mYwbLogo from "../../assets/images/m-logo.svg";
import ywbMenu from "../../assets/images/menu.svg";
import CustomButton from "../../ywbDesignSystem/button";
import "./header.scss";
import GetTheApp from "../sigInSignUp/getTheApp";
import SignIn from "../sigInSignUp/signIn";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { guestUserNavLinks } from "./headerUtils";
import { useNavigate, useSearchParams } from "react-router-dom";
import leftArrow from "../../assets/images/left-arrow.svg";
import circleCrossIcon from "../../assets/images/circle-cross.svg";
import infoIcon from "../../assets/images/info.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const theme = createTheme({
  components: {
    MuiList: {
      styleOverrides: {
        root: {
          border: "none!important",
          boxShadow: "none",
          outline: 0,
          "&.MuiMenu-list": {
            height: "250px",
            border: "none",
            boxShadow: "none",
            outline: 0,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid rgba(99, 146, 47, 1)",
          fontSize: "15px",
          fontWeight: "500!important",
          padding: "13px",
          "&:last-of-type": {
            border: "none",
          },
        },
      },
    },
  },
});
const Header = (props) => {
  const [queryString] = useSearchParams(window.location.search);
  const call_from = queryString.get("call_from");
  const [modalType, setModalType] = useState(null);
  const [howItWorksMenu, setHowItWorksMenu] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();

  const navigateToPage = (path) => {
    navigate(path);
  };

  const navigateToHomePage = () => {
    navigateToPage("/");
  };

  const handlePopup = (type = null) => {
    setModalType(type);
  };

  const renderModal = () => {
    switch (modalType) {
      case "signIn":
        return <SignIn handlePopup={handlePopup} />;
      case "getTheApp":
        return <GetTheApp handlePopup={handlePopup} />;
      default:
        return null;
    }
  };

  const handleHowItWorksMenu = (event) => {
    setHowItWorksMenu(event.currentTarget);
  };

  const handleCloseMenu = (event) => {
    event.stopPropagation();
    setHowItWorksMenu(null);
  };

  const handleMenuDrawer = () => {
    setOpenMenu(!openMenu);
  };

  const renderHowItWorks = () => {
    return (
      <Menu
        anchorEl={howItWorksMenu}
        open={!!howItWorksMenu}
        onClose={handleCloseMenu}
        classes="testing"
        MenuListProps={{
          sx:{
            height: '210px!important'
          }
}}
      >

        <MenuList >
          {guestUserNavLinks.map((menuItm) => {
            const { name, path , user_type } = menuItm;
            return (
              localStorage.getItem('user_type') === user_type || user_type === '0' ? (
              <MenuItem onClick={() => navigateToPage(path)} key={name}>
                {name}
              </MenuItem>
              ):null
            );
          })}
        </MenuList>
      </Menu>
    );
  };

  const renderMobMenu = () => {
    return (
      <Drawer open={openMenu} onClose={handleMenuDrawer} className="mob-drawer">
        <Box pl={3} pr={3} pt={2} pb={2}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box display={"flex"} gap={2}>
              <img
                src={leftArrow}
                alt="left arrow"
                onClick={handleMenuDrawer}
              />
              <Typography>Menu</Typography>
            </Box>
            <img
              src={circleCrossIcon}
              alt="cross icon"
              onClick={handleMenuDrawer}
              className="cursor-pointer"
            />
          </Box>
          <List>
            <ListItem
              key={"About Us"}
              onClick={() => navigateToPage("/about-us")}
              disablePadding
            >
              <ListItemButton>
                <img src={infoIcon} alt="info" />
                <ListItemText
                  primary={"About us"}
                  className="about-us-text cursor-pointer"
                />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <Accordion defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className="cursor-pointer">How it works</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {guestUserNavLinks.map((menuItm) => {
                  const { name, path ,user_type } = menuItm;
                  return (
                     localStorage.getItem('user_type') === user_type || user_type === '0' ?
                    <ListItem
                      onClick={() => navigateToPage(path)}
                      key={name}
                      disablePadding
                    >
                      <ListItemButton style={{ paddingLeft: 0 }}>
                        <ListItemText primary={name} />
                      </ListItemButton>
                    </ListItem> :null
                  );
                })}
              </AccordionDetails>
            </Accordion>
          </List>
        </Box>
      </Drawer>
    );
  };

  const renderCtas = () => {
    return (
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        gap={2}
        className="cta-inner-div"
      >
        {localStorage.getItem("user_type") && localStorage.getItem("token") ? (
          <CustomButton
            name="My Dashboard"
            variant="contained"
            color="success"
            onClick={() => {
              navigateToPage("/dashboard");
            }}
            className="cta"
          />
        ) : (
          <>
            <CustomButton
              name="Sign In"
              variant="contained"
              color="success"
              onClick={() => handlePopup("signIn")}
              className="cta"
            />
            <CustomButton
              name="Sign Up"
              variant="outlined"
              color="secondary"
              onClick={() => handlePopup("getTheApp")}
              className="cta"
            />
            <CustomButton
              name="Get the app"
              variant="contained"
              color="secondary"
              onClick={() => handlePopup("getTheApp")}
              className="cta"
            />
          </>
        )}
      </Box>
    );
  };
  return (
    call_from !== "app" && (
      <ThemeProvider theme={theme}>
        <MediaQuery maxWidth={768}>
          <Box
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
            className="header header__mob"
          >
            <Box display="contents">
              <Box p={2} onClick={handleMenuDrawer}>
                <img src={ywbMenu} alt="menu" />
              </Box>
              <Box p={2} onClick={navigateToHomePage}>
                <img src={mYwbLogo} alt="ywb Logo" />
              </Box>
            </Box>
            {renderMobMenu()}
            <Box className="cta-wrapper">{renderCtas()}</Box>
          </Box>
        </MediaQuery>
        <MediaQuery minWidth={769}>
          <Box
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
            className="header header__web"
          >
            <Box p={1} pl={1.5}>
              <img
                src={ywbLogo}
                alt="ywb Logo"
                onClick={navigateToHomePage}
                className="cursor-pointer"
              />
            </Box>
            <Box display={"flex"} color={"white"} width={"50%"}>
              <Typography
                className="nav-link cursor-pointer"
                onClick={() => navigateToPage("/about-us")}
              >
                About Us
              </Typography>
              <Box
                display={"flex"}
                className="nav-link cursor-pointer"
                onClick={handleHowItWorksMenu}
              >
                <Typography>How it works</Typography>
                {howItWorksMenu ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                {howItWorksMenu && renderHowItWorks()}
              </Box>
            </Box>
            {renderCtas()}
          </Box>
        </MediaQuery>
        {modalType && renderModal()}
      </ThemeProvider>
    )
  );
};

export default Header;
