import React from 'react'
import { TextField, ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          height: '48px',
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: `#6F7E8C`,
            },
          }
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.2rem',
          lineHeight: '1.2rem'
        },
        asterisk: {
          color: 'red'
        }
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: '#454545',
          }
        }
      }
    }
    // MuiFilledInput: {
    //   styleOverrides: {
    //     root: {
    //       '&::before, &::after': {
    //         borderBottom: '#E0E3E7',
    //       },
    //       '&:hover:not(.Mui-disabled, .Mui-error):before': {
    //         borderBottom: '2px solid #B2BAC2',
    //       },
    //       '&.Mui-focused:after': {
    //         borderBottom: '2px solid #6F7E8C',
    //       },
    //     },
    //   },
    // },
    // MuiInput: {
    //   styleOverrides: {
    //     root: {
    //       '&::before': {
    //         borderBottom: '2px solid #E0E3E7',
    //       },
    //       '&:hover:not(.Mui-disabled, .Mui-error):before': {
    //         borderBottom: '2px solid #B2BAC2)',
    //       },
    //       '&.Mui-focused:after': {
    //         borderBottom: '2px solid #6F7E8C',
    //       },
    //     },
    //   },
    // },
    // MuiInputLabel: { // Name of the component ⚛️ / style sheet
    //   styleOverrides: {
    //     root: {
    //       '&.Mui-focused, ': { // Name of the rule
    //         display: 'none'
    //       }
    //     }
    //   }
    // }
  }
});
const CustomTextField = (props) => {


  const { name, ...restProps } = props
  return <ThemeProvider theme={theme}>
    <TextField {...restProps} name={name} />
  </ThemeProvider>
}

export default CustomTextField

