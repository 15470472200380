import { Box, Typography } from '@mui/material'
import React from 'react'
import circleCrossIcon from '../../assets/images/circle-cross.svg'

const Title = ({ title, description, handlePopup }) => {
  return (
    <Box display='flex' flexDirection='column' gap={3}>
      <Box display="flex" justifyContent='space-between'>
        <Typography variant='h5' fontWeight={700}>{title}</Typography>
        <img src={circleCrossIcon} alt='cross icon' onClick={handlePopup} className='cursor-pointer' />
      </Box>
      <Typography>{description}</Typography>
    </Box>
  )
}

export default Title