import React, { useState } from 'react'
import downloadAppYWBLogo from '../../assets/images/download-app-bg.svg'
import { Box, Typography } from '@mui/material'
import CustomButton from '../../ywbDesignSystem/button'
import ShareWithFrnd from '../sigInSignUp/shareWithFrnd'
import GetTheApp from '../sigInSignUp/getTheApp'
import playStoreIcon from '../../assets/images/google-play.svg'
// import appStoreIcon from '../../assets/images/apple-store.svg'
import MediaQuery from 'react-responsive'
const DownloadAppWrapper = () => {
  const [modalType, setModalType] = useState(null)

  const handlePopup = (type = null) => {
    setModalType(type)
  }

  const renderModal = () => {
    switch (modalType) {
      case 'shareWithFrnd':
        return <ShareWithFrnd handlePopup={handlePopup} />
      case 'getTheApp':
        return <GetTheApp handlePopup={handlePopup} />
      default:
        return null
    }
  }

  const renderContent = () => {
    return <>
      <Box display='flex' flexDirection='column' className='head'>
        <Typography className='head'> Download & Install Your Wallet Botswana now </Typography>
        <Typography className='head1'> and start enjoying Digital </Typography>
        <Typography className='head1'> Payment Convenience! </Typography>
      </Box>
      <Box display='flex' flexDirection='column' className=''>
        <Typography className='sub-head'>
          Enroll today to explore a diverse array of incredible features, such as effortless transactions,
          speedy payments, and effective financial oversight.
        </Typography>
      </Box>
    </>
  }

  const renderBtn = () => {
    return <>
      <CustomButton variant='contained' color='secondary' name='Share with Friends & Family' className='cta cta1' onClick={() => handlePopup('shareWithFrnd')} />
      <CustomButton variant='contained' color='success' name='Get the App' className='cta cta2' onClick={() => handlePopup('getTheApp')} />
    </>
  }

  const renderAppAction = () => {
    return <div className="app-actions">
      <img src={playStoreIcon} alt='playstore' className='app-action1' />
      {/* <img src={appStoreIcon} alt='appStore' className='app-action2' /> */}
    </div>
  }
  return <>
    <MediaQuery maxWidth={768}>
      <Box className="download-app-wrapper download-app-wrapper__mob">
        <div className="download-app">
          {renderContent()}
          <Box>
            {renderBtn()}
          </Box>
          {renderAppAction()}
        </div>
      </Box>
    </MediaQuery>
    <MediaQuery minWidth={769}>
      <Box display='flex' className="download-app-wrapper download-app-wrapper__web">
        <div className="download-app">
          {renderContent()}
          <Box display={'flex'} gap={2}>
            {renderBtn()}
          </Box>
          {renderAppAction()}
        </div>
        <div className='wrapper-logo'>
          <img src={downloadAppYWBLogo} alt='download app' />
        </div>
      </Box>
    </MediaQuery>
    {renderModal()}
  </>
}

export default DownloadAppWrapper