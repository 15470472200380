import React, { useState } from 'react'
import { NavLink, useSearchParams } from 'react-router-dom'
import fLogo from '../../assets/images/f-logo.svg'
import './footer.scss';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import { footerLinks } from './footerUtils';
import CustomTextField from '../../ywbDesignSystem/textField';
import CustomButton from '../../ywbDesignSystem/button';
import SocialLinks from '../socialLinks';
import MediaQuery from 'react-responsive';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Footer = () => {
  const [queryString] = useSearchParams(window.location.search)
  const call_from = queryString.get('call_from')
  const [email, setEmail] = useState('')

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const renderFooterLinkChildren = (children) => {
    return children.map(childLink => {
      const { name, path } = childLink
      return <NavLink to={path} key={name}>{name}</NavLink>
    })

  }

  const renderAccordianFooterLinks = () => {
    return <>
      {
        footerLinks.map(linkCol => {
          const { title, children } = linkCol
          return <Accordion className='link-box' key={title}>
            <AccordionSummary expandIcon={<ExpandMoreIcon className='expand-icon'/>}>
              <Typography className='head'>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {renderFooterLinkChildren(children)}
            </AccordionDetails>
          </Accordion>
        })
      }
    </>
  }

  const renderFooterLinks = () => {
    return footerLinks.map(linkCol => {
      const { title, children } = linkCol
      return <div className="link-box" key={title}>
        <div className="head">{title}</div>
        {renderFooterLinkChildren(children)}
      </div>
    })
  }

  const renderHeader = () => {
    return <div className="f-header">
      <Grid container>
        <Grid item md={7} width={100}>
          <div className="f-logo">
            <img src={fLogo} alt='ywblogo' />
          </div>
        </Grid>
        <Grid item md={5}>
          <div className="f-text">
            Your Wallet Botswana is an e-wallet app that allows you to store money, make payments, and perform other financial transactions electronically through your mobile device.
          </div>
        </Grid>
      </Grid>
    </div>
  }
  const renderContent = () => {
    return <>
      <div className="newsletter">
        <div className="head">
          SUBSCRIBE TO OUR NEWSLETTER
        </div>
        <div className="text">
          Subscribe today to receive the latest updates, exclusive content, and valuable insights delivered right to your inbox.
        </div>
        <Box className='subscribe'>
          <CustomTextField placeholder="Enter your email" className='subscribe__email' value={email} onChange={handleEmailChange} />
          <CustomButton name='Subscribe' variant='contained' color='success' className='subscribe__button' />
        </Box>
      </div>
    </>
  }

  return (
    call_from !== 'app' && (
      <>
      <MediaQuery maxWidth={768}>
        <div className='footer footer__mob'>
          {renderHeader()}
          <div className="links">
            {renderAccordianFooterLinks()}
            {renderContent()}
          </div>
          <SocialLinks />
        </div>

      </MediaQuery>
      <MediaQuery minWidth={769}>
        <div className='footer footer__web'>
          {renderHeader()}
          <div className="links">
            {renderFooterLinks()}
            {renderContent()}
          </div>
          <SocialLinks />
        </div>
      </MediaQuery>
      </>
    )
    
  )
}

export default Footer
