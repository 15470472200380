import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import React, { useState } from 'react'
import plus from '../../assets/images/plus.svg';
import minus from '../../assets/images/minus.svg';

export const SingleFaqQues = (props) => {
  const { quesItm, defaultExpanded = false } = props
  const { ques, ans , points=[] , afterPoints } = quesItm
  const [expanded, setIsExpanded] = useState(defaultExpanded)

  const renderPoints = (points) => {
    return points.type === 'ol' ? (
      <ol style={{ paddingLeft: "15px" }}>
        {points.list?.map((item,index) => {
          return (
            <li key={index}>
              <Typography>{item}</Typography>
            </li>
          );
        })}
      </ol>
    ) : (
      <ul style={{ paddingLeft: "15px" }}>
        {points.list?.map((item,index) => {
          return (
            <li key={index}>
              <Typography>{item}</Typography>
            </li>
          );
        })}
      </ul>
    );
  };

  const collapseIcon = <img src={plus} alt='plus' />
  const expandedIcon = <img src={minus} alt='minus' />
  return <Accordion defaultExpanded={expanded} onChange={() => setIsExpanded(!expanded)}>
    <AccordionSummary expandIcon={expanded ? expandedIcon : collapseIcon}>
      <Typography>{ques}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography>{ans}</Typography>
      {points && renderPoints(points)}
      <Typography>{afterPoints}</Typography>
    </AccordionDetails>
  </Accordion>
}
